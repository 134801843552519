import React from 'react'
import { Box, Card, Flex, Heading, Text, Link } from 'rebass'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import ContactForm from '../components/contactform'

const Contattaci = () => {
  return (
    <Layout>
      <SEO title="Contattaci" />
      <Box p={5} bg="#D9E8ED">
        <Heading
          fontSize={6}
          fontWeight="500"
          textAlign="center"
          fontFamily="Playfair Display, serif"
        >
          Contattaci
        </Heading>
      </Box>
      <Container mb={6}>
        <Flex flexWrap="wrap" py={4}>
          <ContactForm />
          <Box width={[1, 1, 1 / 3]}>
            <Card
              p={3}
              borderRadius={4}
              boxShadow="1px 1px 2px 1px rgba(0,0,0,0.1)"
            >
              <Heading mb={3} fontWeight="600" fontSize={3}>
                Contatti
              </Heading>
              <Box mb={4}>
                <Text mb={1}>
                  Telefono:{' '}
                  <Link color="#F06449" href="tel:011-19713206">
                    011-19713206
                  </Link>
                </Text>
                <Text mb={1}>
                  Fax:{' '}
                  <Link color="#F06449" href="tel: 011-2735237">
                    011-2735237
                  </Link>
                </Text>
                <Text mb={1}>
                  PEC:{' '}
                  <Link
                    color="#F06449"
                    href="&#109;ailto&#58;safetya%6&#52;viso%72s&#64;pe&#99;%&#50;E&#105;%74"
                  >
                    &#115;a&#102;e&#116;y&#97;d&#118;isor&#115;&#64;pec&#46;it
                  </Link>
                </Text>
              </Box>
              <Heading mb={3} fontWeight="600" fontSize={3}>
                Amministrazione
              </Heading>
              <Box mb={4}>
                <Text mb={1}>Dott.ssa. Federica Germinaro</Text>
                <Link
                  color="#F06449"
                  href="&#109;&#97;il&#116;o&#58;%66&#37;65%&#54;4%6&#53;r&#105;ca&#64;s%61fe&#116;yad%&#55;6%6&#57;sors&#46;&#37;&#54;9t"
                >
                  f&#101;&#100;eri&#99;a&#64;safe&#116;&#121;&#97;&#100;v&#105;sors&#46;&#105;&#116;
                </Link>
              </Box>

              <Heading mb={3} fontWeight="600" fontSize={3}>
                Servizio tecnico
              </Heading>
              <Box mb={3}>
                <Text mb={1}>Ing. Antonio Germinaro</Text>
                <Link
                  color="#F06449"
                  href="m&#97;ilto&#58;&#97;%6&#69;ton&#105;o&#64;sa&#102;etya&#100;visor&#115;&#46;i&#116;"
                >
                  an&#116;&#111;ni&#111;&#64;safetyadvisors&#46;it
                </Link>
              </Box>

              <Box mb={3}>
                <Text mb={1}>Dott.ssa. Anna Maria Di Biaso</Text>
                <Link color="#F06449" href="mailto:annamaria.dibiaso@gmail.com">
                  annamaria.dibiaso@gmail.com
                </Link>
              </Box>

              <Box mb={3}>
                <Text mb={1}>Dott.ssa. Federica Germinaro</Text>
                <Link
                  color="#F06449"
                  href="&#109;&#97;il&#116;o&#58;%66&#37;65%&#54;4%6&#53;r&#105;ca&#64;s%61fe&#116;yad%&#55;6%6&#57;sors&#46;&#37;&#54;9t"
                >
                  f&#101;&#100;eri&#99;a&#64;safe&#116;&#121;&#97;&#100;v&#105;sors&#46;&#105;&#116;
                </Link>
              </Box>
            </Card>
          </Box>
        </Flex>
        <Heading
          fontSize={5}
          fontWeight="500"
          fontFamily="Playfair Display, serif"
          mt={5}
          mb={5}
          textAlign="center"
        >
          Vieni a trovarci
        </Heading>
        <Box
          width={1}
          css={{
            height: 0,
            paddingBottom: 900 / 24 + '%',
            position: 'relative',
            overflow: 'hidden',
            '& > iframe': {
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              bottom: 0,
              left: 0,
              border: 0,
            },
          }}
        >
          <iframe
            width="100%"
            height="600"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2816.4783470573248!2d7.733594915545169!3d45.09637047909826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478873e0b3288771%3A0x98f1cf3cd9b1e599!2sSafety+Advisors+S.R.L.!5e0!3m2!1sen!2sit!4v1558085430996!5m2!1sen!2sit"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="map"
          />
        </Box>
      </Container>
    </Layout>
  )
}

export default Contattaci

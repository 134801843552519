import React, { useState } from 'react'
import { Flex, Box, Heading, Text } from 'rebass'
import styled from 'styled-components'

const FormInput = styled.input`
  padding: 16px;
  width: 100%;
  font-family: Work Sans, sans-serif;
  border-radius: 4px;
  border: 1px solid #f3f3f3;
`

const FormMessage = styled.textarea`
  padding: 8px;
  width: 100%;
  font-family: Work Sans, sans-serif;
  border-radius: 4px;
  border: 1px solid #f3f3f3;
  min-height: 100px;
`

const Submit = styled.button`
  font-family: Work Sans, sans-serif;
  font-size: 1em;
  border: none;
  border-radius: 2px;
  background-color: #f06449;
  color: white;
  padding: 8px 16px;
  margin: 0 auto;
  cursor: pointer;
`

const ContactForm = () => {
  const [fields, setFields] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const form = e.target

    if (fields.botfilter) {
      setLoading(false)
      setSubmitted(true)
      return
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...fields,
      }),
    })
      .then(() => {
        setLoading(false)
        setSubmitted(true)
      })
      .catch(_ => {
        setLoading(false)
        setSubmitted(false)
      })
  }

  const handleChange = e => {
    setFields({ ...fields, [e.target.name]: e.target.value })
  }

  return (
    <Box width={[1, 1, 2 / 3]} px={[0, 0, 4]} mb={[4, 4, 0]}>
      <Heading fontSize={4} fontWeight="500" mb={3} p={2}>
        Scrivici un messaggio
      </Heading>
      {!submitted && (
        <form
          name="Contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="botfilter"
          onSubmit={e => handleSubmit(e)}
        >
          <Flex flexWrap="wrap">
            <input type="hidden" name="form-name" value="Contact" />
            <input
              type="hidden"
              name="botfilter"
              onChange={e => handleChange(e)}
            />
            <Box width={1 / 2} p={2}>
              <FormInput
                type="text"
                name="name"
                placeholder="Nome"
                onChange={e => handleChange(e)}
                required
              />
            </Box>
            <Box width={1 / 2} p={2}>
              <FormInput
                type="text"
                name="surname"
                placeholder="Cognome"
                onChange={e => handleChange(e)}
                required
              />
            </Box>
            <Box width={1 / 2} p={2}>
              <FormInput
                type="text"
                name="businessname"
                placeholder="Azienda"
                onChange={e => handleChange(e)}
              />
            </Box>
            <Box width={1 / 2} p={2}>
              <FormInput
                type="email"
                name="email"
                placeholder="Email"
                onChange={e => handleChange(e)}
                required
              />
            </Box>
            <Box width={1} p={2}>
              <FormMessage
                placeholder="Il tuo mesaggio"
                name="message"
                onChange={e => handleChange(e)}
                required
              />
            </Box>
            <Box width={1} p={2} css={{ textAlign: 'center' }}>
              <Submit type="submit">{loading ? '...' : 'Invia'}</Submit>
            </Box>
          </Flex>
        </form>
      )}
      {submitted && (
        <Text mt={3} p={2}>
          Grazie per averci contattato!
        </Text>
      )}
    </Box>
  )
}

export default ContactForm
